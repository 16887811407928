import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { useMemo } from "react";
import { proxy, useSnapshot } from "valtio";
import { MediaServiceClient } from "../../generated-protos/api/media/grpc/media/v1/media.client";
import { PayloadPartInfo } from "../types/OmaNms";
import {
  cleanPhoneNumber,
  isSamePhoneNumber,
} from "./messaging/conversation/conversationUtils/phoneNumberUtils";

const _env_ = window._env_,
  _domain = window.location.hostname.replace(/^(verse|www)\./, "");
if (!import.meta.env.DEV && _domain !== "localhost") {
  // no change for localhost
  _env_["NMS_URL"] = _env_["NMS_URL"].replace(/nms.*:/, "nms." + _domain + ":");
  _env_["FTHTTP_URL"] = _env_["FTHTTP_URL"].replace(
    /fthttp.*:/,
    "fthttp." + _domain + ":"
  );
}
export const noop = () => {};

export const {
  WEB_GW_URL: baseWebGwUrl,
  NMS_URL: baseNMSUrl,
  FTHTTP_URL: baseFthttpUrl,
  DIRECTOR_URL: directorUrl,
  ROUTER_URL: routerUrl,
  SIGNALING_SERVER_URL: signalingServerUrl,
  MEDIA_SERVICE_URL: baseMediaServiceUrl,
  ACS_URL: baseAcsUrl,
  GEOIP_URL: baseGeoIpUrl,
  ONBOARDING_ODIENCE_AUTHTOKEN: onboardingOdienceAuthToken,
  VOICE_BOT_URL: baseVoiceBotUrl,
  DOMAIN: domainUrl,
  VERSE_ENV: verseEnv,
  VERSE_WEB_UI_VERSION: versionVerseWebUi,
  VERSE_WEB_GW_VERSION: versionVerseWebGw,
} = _env_;
if (import.meta.env.DEV) {
  console.debug("baseWebGwUrl", baseWebGwUrl);
  console.debug("baseNMSUrl", baseNMSUrl);
  console.debug("directorUrl", directorUrl);
  console.debug("routerUrl", routerUrl);
  console.debug("signalingServerUrl", signalingServerUrl);
  console.debug("baseMediaServiceUrl", baseMediaServiceUrl);
  console.debug("onboardingOdienceToken", onboardingOdienceAuthToken);
  console.debug("baseVoiceBotUrl", baseVoiceBotUrl);
}
let mediaServiceClient: MediaServiceClient | undefined;
export const MediaServiceProvider = () => {
  if (mediaServiceClient) return mediaServiceClient;
  const transport = new GrpcWebFetchTransport({
    baseUrl: baseMediaServiceUrl,
  });
  return new MediaServiceClient(transport);
};

export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

export function DOMContentLoaded() {
  return new Promise<boolean>((resolve) => {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive" ||
      // @ts-expect-error
      document.readyState === "loaded"
    ) {
      resolve(true);
    } else {
      window.addEventListener("DOMContentLoaded", () => resolve(false), {
        once: true,
      });
    }
  });
}

export const telOrSipPrefixLength = 4;

export function getBotNameFromId(str: string, includeFullSip = false) {
  // return str.match(/(?<name>\w+)-\w+/)?.groups?.name || str;
  const cutIndex = includeFullSip ? str.indexOf("@") : str.indexOf("-");
  if (cutIndex === -1) {
    return str;
  }
  return str.substring(str.startsWith("sip:") ? 4 : 0, cutIndex);
}

export function chatbotToPhoneNumber(bot_id: string): [string, string] {
  const chatbotPhoneNumber: [string, string] = [
    bot_id.substring(1, bot_id.length - 1),
    "",
  ];
  return chatbotPhoneNumber;
}

export function convertToCRLF(input: string) {
  return input.replaceAll(/\r?\n/g, "\r\n");
}

export function isValidPhoneNumber(phoneNumber: string) {
  // The minimum length of a phone number without international prefix is 5
  return cleanPhoneNumber(phoneNumber).replaceAll(/[^0-9+]/g, "").length >= 5;
}

/**
 *
 * @param item item to filter
 * @param query query to filter with
 * @param filterType the type of the filter to use
 * @param properties properties to filter with
 * @returns a tuple with the first element being the item and the second element being an object with the indices of the strings of the properties that matched the query
 */
export function filterItem<
  T /* extends Record<string, string | string[] | undefined> */,
  K extends keyof T,
>(
  item: T,
  query: string,
  filterType: FilterType = FilterType.PARTIAL,
  ...properties: K[]
) {
  const indices = {} as {
    [P in K as `${Extract<P, string>}Index`]:
      | (T[P] extends string | undefined ? number : [number, number])
      | undefined;
  };

  for (const property of properties) {
    const itemVal = item[property] as T[keyof T];
    if (!itemVal) {
      continue;
    }
    let index: number | [number, number] | undefined;
    const isPhone = typeof property === "string" && property.includes("phone");

    if (typeof itemVal === "string") {
      const currentVal = isPhone
        ? cleanPhoneNumber(itemVal)
        : itemVal?.toLowerCase();

      let indexOfQuery = currentVal.indexOf(query);

      if (
        // Match could be perfect for phone so index 0 is valid
        indexOfQuery <= 0 &&
        (filterType === FilterType.PHONE_NUMBER || isPhone)
      ) {
        /**
         * TODO
         * We return here 1 to indicate that we match the query, but the index is wrong.
         * Index is used to highlight text but we disable in the caller components for phone numbers since numbers can be in many format like with (), spaces, etc. and this wont support it
         */
        if (indexOfQuery === 0 || isSamePhoneNumber(currentVal, query)) {
          indexOfQuery = 1;
        }
      }

      if (indexOfQuery >= 0) {
        index = indexOfQuery;
      }
    } else if (Array.isArray(itemVal)) {
      for (let i = 0; i < itemVal.length; i++) {
        const currentVal = isPhone
          ? cleanPhoneNumber(itemVal[i][0]?.toLowerCase())
          : itemVal[i][0]?.toLowerCase();

        let indexOfQuery = currentVal.indexOf(query);

        if (
          // Match could be perfect for phone so index 0 is valid
          indexOfQuery <= 0 &&
          (filterType === FilterType.PHONE_NUMBER || isPhone)
        ) {
          /**
           * TODO
           * We return here 1 to indicate that we match the query, but the index is wrong.
           * Index is used to highlight text but we disable in the caller components for phone numbers since numbers can be in many format like with (), spaces, etc. and this wont support it
           */
          if (indexOfQuery === 0 || isSamePhoneNumber(currentVal, query)) {
            indexOfQuery = 1;
          }
        }

        if (indexOfQuery !== undefined && indexOfQuery !== -1) {
          index = [i, indexOfQuery];
          break;
        }
      }
    } else {
      throw new Error("item value is neither string nor string[]");
    }
    if (index !== undefined) {
      indices[`${property as Extract<K, string>}Index`] = index as any; // ? a short but weirdest type error I've ever seen - so I just casted it to any
    }
  }

  if (Object.keys(indices).length > 0) {
    return [item, indices] as const;
  }
}

export enum FilterType {
  PARTIAL,
  PHONE_NUMBER,
}

export enum FilterTypeFields {
  ALL,
  NAME,
  EMAIL,
  PHONE_NUMBER,
}

export function proxyToRaw<T extends object>(proxy: T): T {
  if (
    typeof proxy !== "object" ||
    proxy === null ||
    proxy instanceof Date ||
    proxy instanceof Set ||
    proxy instanceof Map
  ) {
    return proxy;
  }

  if (Array.isArray(proxy)) {
    return proxy.map((item) => proxyToRaw(item)) as any;
  }

  const obj: any = {};
  for (const [key, value] of Object.entries(proxy)) {
    obj[key] = proxyToRaw(value);
  }
  return obj;
}

export function useTempProxy<T extends object>(obj: T) {
  return useMemo(() => proxy(obj), []);
}

export function useSnapshotAcceptUndefined<T extends object | undefined>(
  obj: T
): T | undefined {
  const temp = useTempProxy({});
  const snap = useSnapshot(obj || temp) as T;
  return obj ? snap : undefined;
}

export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function preloadImage(url: string) {
  return new Promise<void>((resolve) => {
    const img = new Image();
    img.onload = () => resolve();
    img.src = url;
  });
}

export function parseMultipartMixed(
  input: string,
  boundary: string,
  parts: PayloadPartInfo[] = []
) {
  const sections = input.split(`--${boundary}`);

  for (const section of sections) {
    if (section.trim() === "--") {
      continue;
    }

    const lines = section.trim().split("\r\n");
    let part: PayloadPartInfo | undefined = undefined;
    let inHeaders = true;

    for (const line of lines) {
      if (inHeaders && line === "") {
        inHeaders = false;
        continue;
      }

      if (inHeaders) {
        const [header, value] = line.split(": ");
        switch (header) {
          case "Content-Type":
            part ??= {} as PayloadPartInfo;
            part.contentType = value;
            break;
          case "Content-Length": {
            const size = Number(value);
            if (size) {
              part ??= {} as PayloadPartInfo;
              part.size = size;
            }
            break;
          }
        }
      } else {
        part ??= {} as PayloadPartInfo;
        part.textContent ??= "";
        part.textContent += `${line}\r\n`;
      }
    }

    if (part) {
      parts.push(part);
    }
  }

  return parts;
}

export function extractMultipartBoundary(s: string) {
  const boundaryStart = 'boundary="';
  const boundaryEnd = '"';

  const boundaryStartIndex = s.indexOf(boundaryStart);
  if (boundaryStartIndex !== -1) {
    const boundaryEndIndex = s.indexOf(
      boundaryEnd,
      boundaryStartIndex + boundaryStart.length
    );
    if (boundaryEndIndex !== -1) {
      return s.substring(
        boundaryStartIndex + boundaryStart.length,
        boundaryEndIndex
      );
    }
  }

  return null;
}

export function transformScrollHorizontal(
  event: WheelEvent | React.WheelEvent
) {
  const dx = event.deltaY + event.deltaX;

  (event.currentTarget as HTMLElement).scrollLeft += dx;
  event.preventDefault();

  return dx;
}

export function clearSelection() {
  window.getSelection()?.removeAllRanges();
}
