import { ServiceCapabilityArray } from "@/types/capabilities";

export const contactAttributeNamesArrayTyped = [
  "address",
  "address; home",
  "address; work",
  "email; home",
  "email; work",
  "email",
  "phone; mobile",
  "phone; home",
  "phone; work",
  "phone",
  "person; Mother",
  "person; Father",
  "person; Sibling",
  "person; Family",
  "person; Friend",
  "person; Other",
  "person",
] as const;

type ContactAttributeArrayTyped = {
  name: (typeof contactAttributeNamesArrayTyped)[number];
  value: string[];
};

type ContactAttributeStringTyped = {
  // TODO - Deprecated name
  name:
    | "name"
    | "firstName"
    | "lastName"
    | "company"
    | "url"
    | "birthday"
    | "note"
    | "photo";
  value: string;
};

export type ContactAttribute =
  | ContactAttributeArrayTyped
  | ContactAttributeStringTyped;

export type ContactFromRes = {
  attributeList: {
    attribute: ContactAttribute[];
  };
  contactId: string;
  sourceURI: string;
};

export type ContactsResponse = {
  contactCollection: {
    contact: ContactFromRes[];
  };
};

export function attrHasArrayValue(
  attr: ContactAttribute
): attr is ContactAttributeArrayTyped {
  return contactAttributeNamesArrayTyped.includes(attr.name as any);
}

export type ContactAttributeIndexable = Partial<{
  [K in ContactAttribute["name"]]:
    | (K extends ContactAttributeArrayTyped["name"]
        ? Array<ContactAttributeArrayTyped["value"]> // ! there could be multiple values, so they are mapped to an array.
        : ContactAttributeStringTyped["value"])
    | undefined;
}>;

export type NumberWithType = [
  phoneNumber: string,
  type: string,
  caps: ServiceCapabilityArray,
];

export const ODIENCE_VIDEO_WALL_PHONE_INCLUDE = "514888";
export const ODIENCE_FRONT_ROW_PHONE_INCLUDE = "514666";
