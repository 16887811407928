import {
  PopupOverlayBodyCSS,
  PopupOverlayButtonCSS,
  PopupOverlayCSS,
  PopupOverlayCloseButtonCSS,
} from "@/index.style";
import { paths } from "@/routerPaths";
import { atoms } from "@/utils/helpers/atoms";
import { useCall } from "@/utils/hooks/useCall";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useSetAtom } from "jotai";
import { motion } from "motion/react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar, AvatarBackground } from "../shared/Avatar";
import { buttonCommon } from "../shared/Button";
import PopupOverlay from "../shared/PopupOverlay";
import { EndCallButtons } from "./Overlay.style";

interface SmallOverlayProps {
  children: ReactNode;
}

interface IncomingCallOverlayProps {
  isVideo: boolean;
  contact: WebGwContact;
  answerAudio: () => void;
  answerVideo: () => void;
  rejectCall: () => void;
  disableAudioCallingSupport?: boolean;
}

export function IncomingContactAvatar({ contact }: { contact: WebGwContact }) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <Avatar
        contact={contact}
        style={
          contact.photo || !contact.initials
            ? { width: "8em", height: "8em" }
            : { height: "3.335em", width: "3.345em", fontSize: "3em" }
        }
      />
      <div
        css={{
          margin: "0",
          whiteSpace: "nowrap",
          fontSize: "2em",
        }}
      >
        {contact.noNameReturnPhoneNumber()}
      </div>
    </div>
  );
}

function SmallOverlay({ children }: SmallOverlayProps) {
  return (
    <motion.div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

const ActionButton = ({ onClick, backgroundColor, Icon }) => (
  <button onClick={onClick}>
    <AvatarBackground
      css={{
        backgroundColor,
        width: "3em",
        height: "3em",
      }}
    >
      <Icon css={{ color: "white", width: "1em", height: "1em" }} />
    </AvatarBackground>
  </button>
);

const CallInfo = ({ label, Icon }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5em",
    }}
  >
    <div>{label}</div>
    <Icon />
  </div>
);

export function IncomingCallOverlay({
  isVideo,
  contact,
  answerAudio,
  answerVideo,
  rejectCall,
  disableAudioCallingSupport,
}: IncomingCallOverlayProps) {
  if (!contact) return null;

  return (
    <SmallOverlay>
      <div
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: "#1A2026",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: "white",
          padding: "3vw",
        }}
      >
        <div>
          <IncomingContactAvatar contact={contact} />
        </div>

        {isVideo ? (
          <CallInfo label="Incoming Video Call" Icon={VideocamIcon} />
        ) : (
          <CallInfo label="Incoming Call" Icon={PhoneIcon} />
        )}

        <div
          css={{
            gap: "5em",
            display: "flex",
            flexDirection: "row",
            padding: "3vw",
          }}
        >
          <ActionButton
            onClick={rejectCall}
            backgroundColor="#DC3851"
            Icon={CallEndOutlinedIcon}
          />

          {(!disableAudioCallingSupport || !isVideo) && (
            <ActionButton
              onClick={answerAudio}
              backgroundColor={colors.secondaryAccentColor}
              Icon={PhoneIcon}
            />
          )}

          {isVideo && (
            <ActionButton
              onClick={answerVideo}
              backgroundColor={colors.secondaryAccentColor}
              Icon={VideocamIcon}
            />
          )}
        </div>
      </div>
    </SmallOverlay>
  );
}

type UpgradeToVideoOverlayProps = {
  onAcceptVideoUpgrade: () => void;
  onDeclineVideoUpgrade: () => void;
};

export function UpgradeToVideoOverlay({
  onAcceptVideoUpgrade,
  onDeclineVideoUpgrade,
}: UpgradeToVideoOverlayProps) {
  return (
    <PopupOverlay>
      <div css={PopupOverlayCSS}>
        <div css={PopupOverlayBodyCSS}>
          <h2 css={{ margin: "0.83em 0" }}>Add Video</h2>
          <button
            css={PopupOverlayCloseButtonCSS}
            onClick={onDeclineVideoUpgrade}
          >
            <CloseIcon />
          </button>
        </div>

        <div css={{ marginBottom: "2vh", width: "100%" }}>
          Would you like to add video to the call?
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <button
            css={[
              PopupOverlayButtonCSS,
              {
                backgroundColor: colors.secondaryBackground,
                color: colors.primaryTextColor,
              },
            ]}
            style={{ width: "8em" }}
            onClick={onDeclineVideoUpgrade}
          >
            Decline
          </button>
          <button
            css={[
              PopupOverlayButtonCSS,
              {
                backgroundColor: "#DC3851",
                color: colors.primaryTextColor,
              },
            ]}
            style={{ width: "8em" }}
            onClick={onAcceptVideoUpgrade}
          >
            Add
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
}

type CloseOverlayButtonProps = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
};
function CloseOverlayButton({
  isFullScreen,
  toggleFullScreen,
}: CloseOverlayButtonProps) {
  return (
    <button
      onClick={toggleFullScreen}
      css={[
        buttonCommon,
        {
          color: colors.primaryTextColor,
          filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
          background: colors.secondaryBackground,
          ":hover": { background: colors.secondaryBackgroundLighter },
        },
      ]}
    >
      <ExpandMoreIcon css={!isFullScreen && { transform: "rotate(180deg)" }} />
    </button>
  );
}

export function CloseOverlayButtonBox({
  isFullScreen,
  isHovered,
  toggleFullScreen,
  ...props
}: CloseOverlayButtonProps & { isHovered: boolean } & React.ComponentProps<
    typeof motion.div
  >) {
  return (
    <motion.div
      {...(!isFullScreen && {
        initial: { y: "-100%" },
        animate: { y: isHovered ? 0 : "-100%" },
      })}
      {...props}
      css={[
        {
          position: "absolute",
          top: "0",
          right: "0",
          padding: isFullScreen ? "1em" : "0.5em",
          zIndex: 9999,
        },
        (props as any).css,
      ]}
    >
      <CloseOverlayButton
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
      />
    </motion.div>
  );
}

type EndCallOverlayProps = {
  isVideo: boolean;
  numberDialed: string;
  handleClose: () => void;
};

export function EndCallOverlay({
  isVideo,
  numberDialed,
  handleClose,
}: EndCallOverlayProps) {
  const { callWithAudio, callWithVideo } = useCall();
  const navigate = useNavigate();
  const contacts = useContacts();
  const setDisplayContactId = useSetAtom(atoms.contacts.displayContactId);

  let contact = contacts?.findWithNumber(numberDialed);
  const hasContact = !!contact;
  if (!hasContact) {
    contact = WebGwContact.fromPhoneNumber(numberDialed);
  }

  const handleSelectConversation = () => {
    if (!numberDialed) {
      return;
    }

    setSelectedConversationId(
      Conversation.getOrCreate({
        phoneNumber: numberDialed,
        contactToLinkIfCreate: contact,
      }).conversation.id
    );
    handleClose();
    navigate(paths.messages);
  };

  const handleNavigateToContact = () => {
    if (contact) {
      setDisplayContactId(contact.id);
      navigate(paths.contacts);
    }
    handleClose();
  };

  const handleMakeCall = async () => {
    handleClose();

    if (isVideo) {
      callWithVideo(numberDialed);
    } else {
      callWithAudio(numberDialed);
    }
  };

  const callIcon = isVideo ? <VideocamIcon /> : <PhoneIcon />;

  return (
    <SmallOverlay>
      <div
        css={{
          width: "fit-content",
          height: "fit-content",
          backgroundColor: colors.primaryBackground,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          color: colors.primaryTextColor,
          padding: "3vw",
        }}
      >
        <div
          css={{ display: "flex", justifyContent: "end", width: "100%" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            alignItems: "center",
          }}
        >
          <IncomingContactAvatar contact={contact!} />
          <div>Call Ended</div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            width: "40vw",
            maxWidth: "30em",
            paddingTop: "10vh",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              gap: "1vh",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              css={[
                EndCallButtons,
                {
                  backgroundColor: colors.secondaryAccentColor,
                },
              ]}
              onClick={handleMakeCall}
            >
              {callIcon} Call Again
            </button>
            <button
              css={EndCallButtons}
              onClick={
                hasContact ? handleNavigateToContact : handleSelectConversation
              }
            >
              <PersonOutlineIcon />
              {hasContact ? "View Contact Card" : "Send a Message"}
            </button>
          </div>
          {hasContact && (
            <div
              css={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button css={EndCallButtons} onClick={handleSelectConversation}>
                Send a Message
              </button>
            </div>
          )}
        </div>
      </div>
    </SmallOverlay>
  );
}
