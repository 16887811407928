import NmsMessage from "@/utils/messaging/NmsMessage";

export type NMSReply = {
  /** id ("imdn.Message-ID") of the message being replied to */
  id: string;
  /** the message being replied to (has the same id as the reply) */
  message?: NmsMessage;
  type: NmsMessage["Reference-Type"];
};

export type Reaction = NMSReply & {
  emoji?: string;
};

export enum SendFileError {
  TOO_BIG,
}

export type GroupChatInfos = {
  iconUrl?: string;
  subject?: string;
  participants?: GroupChatParticipant[];
  date?: Date;
};

export type GroupChatParticipant = {
  phoneNumber: string;
  isJoined: boolean;
  isAdmin: boolean;
};

export type SendMessageResult = {
  messageId: MessageId;
  contributionId: string | undefined;
};

export type MessageContentTypes =
  | "text/plain;charset=UTF-8"
  | "application/vnd.gsma.botsuggestion.response.v1.0+json"
  | "application/vnd.gsma.rcs-ft-http+xml"
  | "application/vnd.gsma.rcspushlocation+xml"
  | "application/vnd.gsma.sharedsketch+xml";

export type ReferenceTypes =
  | `${"+" | "-"}Reaction`
  | "Reply"
  | "Edit"
  | "Delete"
  | "Recall"
  | `${"+" | "-"}Custom-Reaction`;

export type MessageId = string;
export type ChatMessagePayload =
  | {
      isComposing: {
        state: "active" | "inactive";
        contenttype: string;
        refresh: number;
      };
    }
  | {
      chatMessage: {
        text: string;
        contentType: MessageContentTypes;
        referenceType?: ReferenceTypes;
        referenceId?: string | null;
        reportRequest: ["sent", "Delivered", "Displayed"];
      };
    }
  | {
      chatMessage: {
        text: string;
        contentType: MessageContentTypes;
        reportRequest: [];
      };
    };
