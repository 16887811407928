import incomingCallSound from "@/assets/sounds/IncomingCallRingTone.mp3";
import incomingVideoOfferSound from "@/assets/sounds/IncomingVideoOfferRingTone.mp3";
import outgoingCallSound from "@/assets/sounds/OutgoingCallRingTone.mp3";
import {
  ERROR_VIDEO_CALL_PERMISSION,
  ERROR_VOICE_CALL_PERMISSION,
  GRANTED,
  PROMPT,
  checkMicPermissions,
  checkVideoCallPermissions,
} from "@/utils/helpers/browserPermissions";
import {
  LocalHardwareStatusValues,
  checkAvailableHardware,
} from "@/utils/helpers/checkAvailableDeviceHardware";
import { accurateTimer } from "@/utils/helpers/time";
import { useToast } from "@/utils/helpers/toastManager";
import {
  CapabilityType,
  VerseStatus,
  checkCapabilityOnLocalCache,
} from "@/utils/hooks/useCapabilities";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { REJECT_CALL_CODE } from "@/utils/webrtc/webrtc";
import { useNetworkState } from "@uidotdev/usehooks";
import { getDefaultStore, useAtom, useAtomValue, useSetAtom } from "jotai";
import { AnimatePresence, MotionConfig } from "motion/react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import useSound from "use-sound";
import WebGwContact from "../../utils/helpers/WebGwContact";
import {
  getAudioStream,
  getVideoCallStream,
  releaseAudioStream,
  releaseVideoCallStream,
} from "../../utils/helpers/mediaStream";
import {
  CALL_STATE,
  getWebRTC,
  makeAudioCall,
  makeVideoCall,
  switchWebRTC,
} from "../../utils/webrtc/webrtcUtils";

import { CallInfos } from "@/types/calling";
import { atoms } from "@/utils/helpers/atoms";
import { useContacts } from "@/utils/hooks/useContacts";

import { BAD_CONNECTION_TOAST_ID } from "@/utils/constants/common";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useCall } from "@/utils/hooks/useCall";
import ConfirmationPopup from "../shared/ConfirmationPopup";
import { overlayTransition } from "../shared/DraggableOverlay";
import InvitePopup from "../shared/InvitePopup";
import { WeakConnectionAnimation } from "../shared/Loaders/ReconnectingAnimation";
import {
  EndCallOverlay,
  IncomingCallOverlay,
  UpgradeToVideoOverlay,
} from "./Overlay";
import { VideoCallOverlay } from "./VideoOverlay";
import { VoiceCallOverlay } from "./VoiceOverlay";

const LOG_PREFIX = `${CallOverlays.name}`;
const LOG = {
  common: `${LOG_PREFIX}: `,
  components: `${LOG_PREFIX}[component]: `,
};

const defaultStore = getDefaultStore();

export function dispatchCallFailure(failure?: string) {
  console.log(LOG.common, "dispatchCallFailure: failure", failure);

  defaultStore.set(atoms.calling.callFailedError, failure);
}

export default function CallOverlays() {
  const contacts = useContacts();
  const [callFailedError, setCallFailedError] = useAtom(
    atoms.calling.callFailedError
  );
  const [pause, setPause] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);
  const [voiceCallOpen, setVoiceCallOpen] = useState(false);
  const [videoCallOpen, setVideoCallOpen] = useState(false);
  const [contact, setContact] = useState<WebGwContact | undefined>(undefined);
  const [callActive, setCallActive] = useAtom(atoms.calling.callActive);
  const [callMuted, setCallMuted] = useAtom(atoms.calling.callMuted);
  const [outgoingCallInfos, setOutgoingCallInfos] = useAtom(
    atoms.calling.outgoingCallInfos
  );
  const [incomingCallInfos, setIncomingCallInfos] = useAtom(
    atoms.calling.incomingCallInfos
  );
  const streamParticipantsPhoneNumbers = useAtomValue(
    atoms.odience.streamParticipantsPhoneNumbers
  );
  const [incomingVideoOffer, setIncomingVideoOffer] = useState(false);
  const [outgoingVideoOffer, setOutgoingVideoOffer] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const outgoingVideoRef = useRef<HTMLVideoElement | null>(null);
  const [time, setTime] = useState(new Date(0));
  const [isShowInvitePopup, setShowInvitePopup] = useState(false);
  const [playOutgoingRingTone, outgoingRingToneOptions] =
    useSound(outgoingCallSound);
  const [playRingTone, ringToneOptions] = useSound(incomingCallSound);
  const [playRingToneVideoOffer, ringToneVideoOfferOptions] = useSound(
    incomingVideoOfferSound,
    { volume: 0.3 }
  );
  const { showToast } = useToast();
  const [endCallTimeout, setEndCallTimeout] = useState(false);
  const [callEnded, setCallEnded] = useState<CallInfos | undefined>(undefined);
  const [showHardwareModal, setShowHardwareModal] = useAtom(
    atoms.calling.checkAvailableHardware
  );
  const downlink = useRef<number>(0);
  const timeReceived = useRef<number>(0);
  const bytesReceived = useRef<number>(0);
  const framesDecodedRef = useRef<number>(0);
  const frameInc = useRef<number>(0);
  const network = useNetworkState();
  const { callWithAudio, callWithVideo } = useCall();
  const [isVideoWallCall, setIsVideoWallCall] = useAtom(
    atoms.odience.isVideoWallCall
  );
  const [isFrontRowCall, setIsFrontRowCall] = useAtom(
    atoms.odience.isFrontRowCall
  );
  const [disableAudioCallingSupport, setDisableAudioCallingSupport] =
    useState(false);
  const [isFeaturedCaller, setIsFeaturedCaller] = useAtom(
    atoms.odience.featuredCaller
  );
  const setPreviewFrame = useSetAtom(atoms.odience.doUsePreviewFrame);
  const prevIsOdienceCall = useRef<boolean | undefined>(undefined);
  const [mutedByModerator, setMutedByModerator] = useAtom(
    atoms.odience.mutedByModerator
  );

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const consecutiveTime = useRef(0);
  const [waitingForPermissions, setWaitingForPermissions] = useAtom(
    atoms.calling.waitingForPermissions
  );
  const setStreamVolume = useSetAtom(atoms.odience.streamVolume);
  const previousVolume = useAtomValue(atoms.odience.previousVolume);

  if (contact) {
    const newContact =
      contacts?.findWithNumber(contact.getMainPhoneNumber()) ||
      WebGwContact.fromPhoneNumber(contact.getMainPhoneNumber())!;

    if (
      contact.noNameReturnPhoneNumber() !== newContact.noNameReturnPhoneNumber()
    ) {
      console.log("Updating contact information to ", newContact);
      setContact(newContact);
    }
  }

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if (
        callActive ||
        incomingCallInfos ||
        outgoingCallInfos ||
        isVideoWallCall
      ) {
        const confirmationMessage =
          "Are you sure you want to refresh during a call? This will end the call.";
        event.preventDefault();
        event.returnValue = confirmationMessage; // For older browsers
        return confirmationMessage; // For modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [callActive, incomingCallInfos, outgoingCallInfos]);

  const handleDismissEndCall = () => {
    setCallEnded(undefined);
  };

  const playOutgoingCallRingtone = () => {
    outgoingRingToneOptions.sound.loop(true);
    playOutgoingRingTone();
  };

  const stopOutgoingCallRingtone = () => {
    outgoingRingToneOptions.stop();
  };

  const playIncomingCallRingtone = () => {
    ringToneOptions.sound.loop(true);
    playRingTone();
  };

  const stopIncomingCallRingtone = () => {
    ringToneOptions.stop();
  };

  const playIncomingVideoOfferRingtone = () => {
    ringToneVideoOfferOptions.sound.loop(true);
    playRingToneVideoOffer();
  };

  const stopIncomingVideoOfferRingtone = () => {
    ringToneVideoOfferOptions.stop();
  };
  const toggleShowInvitePopup = () => {
    setShowInvitePopup(!isShowInvitePopup);
  };
  const INCOMING_CALL_TIMEOUT = 60 * 1000;

  const webrtc = getWebRTC();

  const showNotImplementedMessage = () =>
    showToast("Not implemented yet", "bottom-right");

  const onCvoChanged = (rotation: number) => {
    console.log(LOG.components, "onCvoChanged: rotation=", rotation);
    setRotationDegree(rotation);
  };

  const onStatUpdated = (stats: RTCStatsReport) => {
    const currentTime = Date.now();

    stats.forEach((report) => {
      if (
        report.kind === "video" &&
        report.type === "inbound-rtp" &&
        currentTime !== timeReceived.current
      ) {
        const bytes = report["bytesReceived"];
        if (bytes) {
          downlink.current =
            (bytes - bytesReceived.current) /
            (currentTime - timeReceived.current);
          bytesReceived.current = bytes;
          timeReceived.current = currentTime;
        }
        const decoded = report["framesDecoded"];
        if (decoded) {
          frameInc.current = decoded - framesDecodedRef.current;
          framesDecodedRef.current = decoded;
        }
        checkNetworkConnection();
      }
    });
  };

  const resetCallStates = () => {
    console.log(LOG.components, "resetCallStates");
    setContact(undefined);
    setOutgoingCallInfos(undefined);
    stopOutgoingCallRingtone();
    stopIncomingCallRingtone();
    stopIncomingVideoOfferRingtone();
    setIncomingCallInfos(undefined);
    setCallMuted(false);
    setVideoCallOpen(false);
    setVoiceCallOpen(false);
    setCallActive(false);
    setIsFullScreen(true); // always make sure call starts in full screen
    setPause(false);
    setIncomingVideoOffer(false);
    setEndCallTimeout(false);
    setIsVideoWallCall(false);
    setIsFrontRowCall(false);
    setPreviewFrame("");
    setIsFeaturedCaller(false);
    releaseVideoCallStream();
    releaseAudioStream();
    setMutedByModerator(false);
    setWaitingForPermissions(false);
    setStreamVolume(previousVolume);
    setDisableAudioCallingSupport(false);
    setOutgoingVideoOffer(false);
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = null;
    }
    if (outgoingVideoRef.current) {
      outgoingVideoRef.current.srcObject = null;
    }
  };

  const setContactFromPhoneNumber = (phoneNumber: string) => {
    console.log(
      LOG.components,
      "setContactFromPhoneNumber: phoneNumber=",
      phoneNumber
    );

    setContact(
      contacts?.findWithNumber(phoneNumber) ||
        WebGwContact.fromPhoneNumber(phoneNumber)
    );
  };

  useEffect(() => {
    if (callFailedError) {
      resetCallStates();
      showToast(callFailedError, "bottom-right");
      setCallFailedError(undefined);
    }
  }, [callFailedError]);

  let toastForNonVerseScheduled = false;

  const onCallStateChange = (
    callState: string,
    remote: string,
    isVideo: boolean
  ) => {
    console.log(
      LOG.components,
      "onCallStateChange: callState=",
      callState,
      ", remote=",
      remote,
      ", isVideo=",
      isVideo
    );

    stopOutgoingCallRingtone();
    stopIncomingCallRingtone();
    stopIncomingVideoOfferRingtone();
    switch (callState) {
      // Incoming state can be for initial call or options during call like video offer
      case CALL_STATE.Incoming:
        if (
          contact &&
          !isSamePhoneNumber(remote, contact?.getMainPhoneNumber())
        ) {
          console.log(
            `Incoming call from ${remote} but call already set with ${contact}, ignoring.`
          );
          return;
        }
        if (callActive) {
          if (isVideo) {
            if (!videoCallOpen) {
              console.log(
                `${LOG.components}onCallStateChange: ${remote} is offering video upgrade`
              );
              playIncomingVideoOfferRingtone();
              setIncomingVideoOffer(true);
              setIsFullScreen(false);
            }
          } else {
            console.log(
              `${LOG.components}onCallStateChange: ${remote} removed his video`
            );
            onRemoteVideoRemoved(true);
          }
        } else {
          playIncomingCallRingtone();
          setContactFromPhoneNumber(remote);
          setIncomingCallInfos({
            number: remote,
            isVideo: isVideo,
            startFullScreen: false,
          });
        }
        break;

      case CALL_STATE.Active:
        // Ignore wrong state coming in if no active outgoing/incoming request
        if (!contact || !remote) {
          console.warn(
            `${LOG.components}onCallStateChange: no outgoing/incoming request, ignoring.`
          );
          return;
        }

        setIncomingCallInfos(undefined);
        // Sometimes video offer is received but directly canceled (either by user or network issue), make sure to dismiss the incoming offer
        if (!isVideo) {
          discardIncomingVideoOffer();
        }

        setCallActive(true);
        setOutgoingVideoOffer(false);

        // Set the correct view depending on the remote acceptation by voice or video
        if (videoCallOpen && !isVideo) {
          if (callActive) {
            showDeclinedVideoOfferToast();
          } else {
            showToast(
              `${contact?.noNameReturnPhoneNumber()} accepted the call as voice`,
              "bottom-right"
            );
          }

          webrtc?.releaseLocalVideoStream(
            outgoingVideoRef.current?.srcObject as MediaStream
          );
          setVoiceCallOpen(true);
          setVideoCallOpen(false);
        }

        // Call could be muted before being active, but will only take effect when tracks are available, hence the mute here
        console.log(
          LOG.components,
          "onCallStateChange: Call was set to mute " +
            callMuted +
            " before being active"
        );

        if (!webrtc?.muteCall(callMuted)) {
          // We revert the mute if any failure on the web rtc layer
          setCallMuted(!callMuted);
        }
        break;
      case CALL_STATE.Outgoing:
        if (!isVideoWallCall && !isFrontRowCall && outgoingCallInfos) {
          // Outgoing video upgrade
          if (callActive) {
            playRingToneVideoOffer();
            setOutgoingVideoOffer(true);
          } else {
            playOutgoingCallRingtone();
          }
        } else {
          setIsFullScreen(false);
          setCallActive(false);
        }
        break;
      case CALL_STATE.NoCall:
      case CALL_STATE.Reject: {
        // Front row we just clear everything
        if (isFrontRowCall) {
          resetCallStates();
          return;
        }

        let verseStatus = VerseStatus.INSTALLED;
        // Since we can start an outgoing call with an unknown number, notify the user if the call ended because no caps
        if (outgoingCallInfos) {
          const res = checkCapabilityOnLocalCache(
            outgoingCallInfos.number,
            outgoingCallInfos.isVideo
              ? CapabilityType.VIDEO
              : CapabilityType.VOICE
          );

          verseStatus = res.verseStatus;
        }

        if (verseStatus === VerseStatus.NOT_INSTALLED) {
          // We put a small delay here since the end call can come pretty fast in case no verse and this avoids a clipping of the overlay
          setTimeout(() => {
            // There could multiple end calls coming in, avoid multiple notifications
            if (!toastForNonVerseScheduled) {
              setShowInvitePopup(true);
            }
            toastForNonVerseScheduled = true;
            resetCallStates();
          }, 1500);
        } else {
          if (callState === CALL_STATE.Reject && outgoingCallInfos) {
            if (!isVideoWallCall && !isFrontRowCall) {
              const wrtc = switchWebRTC();
              if (
                outgoingCallInfos.isVideo &&
                wrtc?.getCallState() == CALL_STATE.Active
              ) {
                outgoingCallInfos.isVideo = false;
                setOutgoingCallInfos(outgoingCallInfos);
                setVoiceCallOpen(true);
                setVideoCallOpen(false);
                showDeclinedVideoOfferToast();
                return;
              }
              setCallEnded(outgoingCallInfos);
            } else {
              showToast(
                `Could not establish connection to ${isVideoWallCall ? "Video Wall" : "Front Row"}. Please try again later.`
              );
            }
          }
          resetCallStates();
        }

        break;
      }
    }
  };

  const showDeclinedVideoOfferToast = () => {
    showToast(
      `${contact?.noNameReturnPhoneNumber()} has declined the video offer`,
      "bottom-right"
    );
  };

  const onRemoteVideoRemoved = async (autoDowngrade: true) => {
    console.log(LOG.components, "onRemoteVideoRemoved");

    let stream: MediaStream | undefined | "waiting" = undefined;
    if (autoDowngrade) {
      stream = await runOrPromptPermissionWaiting(getAudioStream);
      if (stream === "waiting") {
        return;
      }
    }

    webrtc!.acceptRemoveRemoteVideo(autoDowngrade, stream);

    setVoiceCallOpen(autoDowngrade);
    setVideoCallOpen(!autoDowngrade);
    discardIncomingVideoOffer();

    // We make sure to display the toast if the video was in place already
    if (videoCallOpen) {
      showToast(
        `${contact?.noNameReturnPhoneNumber()} has turned off the camera`,
        "bottom-right"
      );
    }
  };

  const discardIncomingVideoOffer = () => {
    if (incomingVideoOffer) {
      setIncomingVideoOffer(false);
      setIsFullScreen(true);
    }
  };

  useEffect(() => {
    if (incomingCallInfos) {
      checkSupportForAudioCalling(incomingCallInfos.number);

      //TODO: check why it does not always detect a second callState change to close the incoming call popup. This is a fallback for those instances to remove popup
      console.log(LOG.components, "useEffect[incomingCall]");
      const timer = setTimeout(() => {
        console.log(
          LOG.components,
          "useEffect[incomingCall]: setting incoming call to false"
        );
        // Do not end call here but use a state, reason is this async block wont see the updated information of the other states, therefore causing issue like not ending the ringtone (null here, but good on latest render of the component)
        setEndCallTimeout(true);
      }, INCOMING_CALL_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [incomingCallInfos]);

  // This effect is used only for timeout, in order to see latest values for all component states
  useEffect(() => {
    console.log(LOG.components, "useEffect[endCallTimeout]");

    if (endCallTimeout) {
      endCall();
    }
  }, [endCallTimeout]);

  // This effect is used only for when on an odience call and we get kicked off by the moderator
  useEffect(() => {
    // Only trigger endCall if the previous value was true and the current value is false

    if (prevIsOdienceCall.current && !isVideoWallCall && !isFrontRowCall) {
      endCall();
    }

    // Update the ref to the current value
    prevIsOdienceCall.current = isVideoWallCall || isFrontRowCall;
  }, [isVideoWallCall, isFrontRowCall, prevIsOdienceCall]);

  const endCall = (rejectCode = -1) => {
    console.log(LOG.components, `endCall rejectCode=${rejectCode}`);

    // Hang up the call
    webrtc?.hangupCall(rejectCode);

    // Reset the call state or UI
    resetCallStates();
  };

  const rejectCall = () => {
    console.log(LOG.components, "rejectCall");

    endCall(REJECT_CALL_CODE);
  };

  const answerAudioCall = async () => {
    console.log(LOG.components, "answerAudioCall");
    const micEnabled = await checkMicPermissions();
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable === LocalHardwareStatusValues.NO_MIC) {
      setShowHardwareModal(true);
    }

    if (micEnabled === GRANTED || micEnabled === PROMPT) {
      console.log(LOG.components, "answerAudioCall");

      const stream = await runOrPromptPermissionWaiting(getAudioStream);
      if (stream === "waiting") {
        return;
      }
      if (!stream) {
        if (micEnabled === PROMPT) {
          showToast(ERROR_VOICE_CALL_PERMISSION);
        } else {
          console.error("Unable to answer audio call: Stream undefined");
          endCall();
        }
        return;
      }
      setIncomingCallInfos(undefined);
      answerCall(stream, false);
    } else {
      showToast(micEnabled);
    }
  };

  const answerVideoCall = async () => {
    const videoCallPermissions = await checkVideoCallPermissions();
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable !== LocalHardwareStatusValues.AVAILABLE) {
      setShowHardwareModal(true);
    }
    console.log("videoCallPermissions=", videoCallPermissions);

    if (videoCallPermissions === GRANTED || videoCallPermissions === PROMPT) {
      console.log(LOG.components, "answerVideoCall");

      const stream = await runOrPromptPermissionWaiting(() =>
        getVideoCallStream(remoteVideoRef, outgoingVideoRef)
      );
      if (stream === "waiting") {
        return;
      }
      if (!stream) {
        if (videoCallPermissions === PROMPT) {
          showToast(ERROR_VIDEO_CALL_PERMISSION);
        } else {
          console.error("Unable to answer video call: Stream undefined");
          endCall();
        }
        return;
      }
      setIncomingCallInfos(undefined);
      answerCall(stream, true);
    } else {
      showToast(videoCallPermissions);
    }
  };

  const answerCall = (stream: MediaStream, isVideo: boolean) => {
    if (webrtc?.answerCall(stream)) {
      setVideoCallOpen(isVideo);
      setVoiceCallOpen(!isVideo);
    } else {
      const remote =
        contact?.noNameReturnPhoneNumber() ||
        formatPhoneNumber(incomingCallInfos?.number || "", "E123");
      showToast(
        `Call${remote ? `with ${remote}` : ``} cannot be established`,
        "top-center"
      );
      endCall();
    }
  };

  const handleAcceptVideoUpgrade = async () => {
    const videoEnabled = await checkVideoCallPermissions();
    if (videoEnabled === GRANTED || videoEnabled === PROMPT) {
      console.log(LOG.components, "handleAcceptVideoUpgrade");

      const stream = await runOrPromptPermissionWaiting(() =>
        getVideoCallStream(remoteVideoRef, outgoingVideoRef)
      );
      if (stream === "waiting") {
        return;
      }
      if (!stream) {
        if (videoEnabled === PROMPT) {
          showToast(ERROR_VIDEO_CALL_PERMISSION);
        } else {
          console.error("Unable to accept video offer: Stream undefined");
          await handleDeclineVideoUpgrade();
        }
        return;
      }
      webrtc!.setMediaStream(stream);
      finalizeVideoUpgrade(true);
    } else {
      showToast(videoEnabled);
    }
  };

  const handleDeclineVideoUpgrade = async () => {
    const stream = await runOrPromptPermissionWaiting(getAudioStream);
    if (stream === "waiting") {
      return;
    }
    webrtc!.setMediaStream(stream);
    finalizeVideoUpgrade(false);
  };

  const sendVideoOffer = (cur) => {
    const wrtc = switchWebRTC();
    if (wrtc) {
      console.log(LOG.components, wrtc.getCallId(), "sendVideoOffer", cur);
      wrtc.setRemoteSdp("\r\nm=video ");
      // inorder to video 0 for downgrade
      if (!cur) callWithVideo(webrtc!.getRemote(), true);
      else callWithAudio(webrtc!.getRemote(), true);
    }
  };

  const finalizeVideoUpgrade = (accepted: boolean) => {
    setVoiceCallOpen(!accepted);
    setVideoCallOpen(accepted);
    discardIncomingVideoOffer();
  };

  const muteCall = () => {
    console.log(LOG.components, "muteCall: callMuted", callMuted);
    if (webrtc?.muteCall(!callMuted)) {
      setCallMuted(!callMuted);
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const isVideoPaused = (isPaused: boolean) => {
    setPause(isPaused);
  };

  useEffect(() => {
    webrtc?.muteCall(mutedByModerator);
    setCallMuted(mutedByModerator);
  }, [mutedByModerator]);

  useEffect(() => {
    if (webrtc) {
      webrtc.setWebrtcCallback(onCvoChanged);
      webrtc.setCallStateChangeCallback(onCallStateChange);
      webrtc.setWebrtcPausedCallback(isVideoPaused);
      webrtc.setOnStatUpdated(onStatUpdated);
    }
  }, [webrtc, onCvoChanged, onCallStateChange, isVideoPaused, onStatUpdated]);

  async function runOrPromptPermissionWaiting<T>(
    fun: () => T
  ): Promise<"waiting" | T> {
    if (waitingForPermissions) {
      showToast("You must accept browser permissions first", "top-right");
      return "waiting";
    }

    setWaitingForPermissions(true);

    try {
      return await fun();
    } finally {
      setWaitingForPermissions(false);
    }
  }

  // This is used to trigger an outgoing call
  useEffect(() => {
    console.log(
      LOG.components,
      "useEffect[outgoingCallInfos]: outgoingCallInfos=",
      outgoingCallInfos
    );

    if (!outgoingCallInfos) {
      return;
    }

    // Check if some cached caps
    const res = checkCapabilityOnLocalCache(
      outgoingCallInfos.number,
      outgoingCallInfos.isVideo ? CapabilityType.VIDEO : CapabilityType.VOICE
    );

    if (res.verseStatus === VerseStatus.NOT_INSTALLED) {
      setShowInvitePopup(true);
      resetCallStates();
      return;
    }

    setIsFullScreen(outgoingCallInfos.startFullScreen);
    setContactFromPhoneNumber(outgoingCallInfos.number);

    checkSupportForAudioCalling(outgoingCallInfos.number);

    runOrPromptPermissionWaiting(() => {
      if (outgoingCallInfos.isVideo) {
        setVideoCallOpen(true);
        setVoiceCallOpen(false);
        makeVideoCall(outgoingCallInfos.number, async () => {
          const stream = await getVideoCallStream(
            remoteVideoRef,
            outgoingVideoRef
          );
          return stream;
        });
      } else {
        setVoiceCallOpen(true);
        setVideoCallOpen(false);
        makeAudioCall(outgoingCallInfos.number, getAudioStream);
      }
    });
  }, [outgoingCallInfos]);

  // When on a stream, checks if remote is currently part of it to disable audio call (Odience app only supports video calls)
  const checkSupportForAudioCalling = (phoneNumber: string) => {
    if (
      streamParticipantsPhoneNumbers.find((current) =>
        isSamePhoneNumber(current, phoneNumber)
      )
    ) {
      setDisableAudioCallingSupport(true);
    }
  };

  // Counter for elapsed time in call
  useEffect(() => {
    if (callActive) {
      const clearTimer = accurateTimer(setTime);
      return () => clearTimer();
    } else {
      setTime(new Date(0));
    }
  }, [callActive]);

  const checkNetworkConnection = () => {
    if ((network.downlink || downlink.current > 0) && callActive) {
      console.log(
        network.downlink,
        "downlink",
        downlink.current,
        "frameInc",
        frameInc.current
      );
      if (
        downlink.current < 100 &&
        bytesReceived.current > 10000 &&
        frameInc.current == 0
      ) {
        consecutiveTime.current += 1; //no inc if not freeze
        // If conditions are met for 3 consecutive seconds
        if (consecutiveTime.current >= 3) {
          toast("Poor Internet Connection. Video quality may be affected", {
            style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
            icon: <WeakConnectionAnimation />,
            id: BAD_CONNECTION_TOAST_ID,
            duration: Infinity,
          });
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          consecutiveTime.current = 0;
        }, 1000);
      } else {
        toast.dismiss(BAD_CONNECTION_TOAST_ID);
        consecutiveTime.current = 0; // Reset if conditions are not met
      }
    } else {
      toast.dismiss(BAD_CONNECTION_TOAST_ID);
      consecutiveTime.current = 0; // Reset when call is not active
    }
  };

  useEffect(() => {
    checkNetworkConnection();

    if (!network.online) {
      // This wont send back to the network the end since no connection, but will make sure to reset all call states locally
      endCall();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [network.online, network.downlink, callActive]);

  return (
    <MotionConfig transition={overlayTransition}>
      <AnimatePresence>
        {incomingCallInfos && (
          <IncomingCallOverlay
            isVideo={incomingCallInfos.isVideo}
            contact={contact!}
            answerAudio={answerAudioCall}
            answerVideo={answerVideoCall}
            rejectCall={rejectCall}
            disableAudioCallingSupport={disableAudioCallingSupport}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {incomingVideoOffer && (
          <UpgradeToVideoOverlay
            onAcceptVideoUpgrade={handleAcceptVideoUpgrade}
            onDeclineVideoUpgrade={handleDeclineVideoUpgrade}
          />
        )}
      </AnimatePresence>
      <VoiceCallOverlay
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
        open={voiceCallOpen}
        callActive={callActive}
        callMuted={callMuted}
        contact={contact}
        sendVideoOffer={() => sendVideoOffer(videoCallOpen)}
        videoCallOpen={videoCallOpen}
        muteCall={muteCall}
        endCall={() => endCall()}
        time={time}
        showNotImplemented={showNotImplementedMessage}
        isPaused={pause}
      />
      <VideoCallOverlay
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
        open={videoCallOpen}
        callActive={callActive}
        callMuted={callMuted}
        rotationDegree={rotationDegree}
        contact={contact}
        videoRef={remoteVideoRef}
        outgoingVideoRef={outgoingVideoRef}
        muteCall={muteCall}
        endCall={() => endCall()}
        sendVideoOffer={() => sendVideoOffer(videoCallOpen)}
        videoCallOpen={videoCallOpen}
        time={time}
        showNotImplemented={showNotImplementedMessage}
        isPaused={pause}
        isVideoWallCall={isVideoWallCall}
        isFrontRowCall={isFrontRowCall}
        isFeaturedCaller={isFeaturedCaller}
        disableAudioCallingSupport={disableAudioCallingSupport}
        outgoingVideoOffer={outgoingVideoOffer}
      />
      <AnimatePresence>
        {callEnded && (
          <EndCallOverlay
            isVideo={callEnded.isVideo}
            numberDialed={callEnded.number}
            handleClose={handleDismissEndCall}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isShowInvitePopup && (
          <InvitePopup togglePopup={toggleShowInvitePopup} />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showHardwareModal && (
          <ConfirmationPopup
            title={"Can't Detect Hardware"}
            confirmationMessage={
              incomingCallInfos?.isVideo || outgoingCallInfos?.isVideo
                ? "Video Call could not be established because no device camera was detected"
                : "Voice Call could not be established because no device microphone was detected"
            }
            handleAction={() => [setShowHardwareModal(false), endCall()]}
            primaryButtonText="Ok"
            togglePopup={() => [setShowHardwareModal(false), endCall()]}
            closeButtonActive={false}
            secondaryButtonActive={false}
          />
        )}
      </AnimatePresence>
    </MotionConfig>
  );
}
