import { Config } from "@/types/common";

import { ModeratorBotInfo } from "@/components/chatScreen/chat/typings";
import { fetchWithTimeout } from "../helpers/Utils";

export type ChatbotInfo = {
  id: string; // "<sip:2084episode01-8b297fe367c4673ed44919e67a20f4a7@botplatform.mnc000.mcc000.3gppnetwork.org>";
  name: string; // "2084 - Episode 01";
  verified: boolean;
  icon: string; // "https://builder.erl.rcs.st/storage/2084episode01-8b297fe367c4673ed44919e67a20f4a7/icon_1580218977.png";
  isCritical: number; // 0
  isFeatured: boolean;
  checksum: string; // "";
  inserted: number; // 0
  modified: number; // 0
  isNew: boolean;
  isUpdated: boolean;
  display_name: string;
  bot_id: string;
  action_image_url: string;
  odience: boolean;
};
type ChatbotDirectoryRes = {
  bots: ChatbotInfo[];
};

const CARRIER_ODIENCE = "odience";
const CARRIER_VERSE = "verse";
const ID_POLLBOT_PARTIAL = "odiencepollbot";

export function isPollBot(chatbot) {
  return !!(
    chatbot.type === "poll" ||
    chatbot.category === "odience_poll" ||
    // Fallback, no type or category, we match the id
    (!chatbot.category &&
      !chatbot.type &&
      (chatbot.id?.includes(ID_POLLBOT_PARTIAL) ||
        chatbot.bot_id?.includes(ID_POLLBOT_PARTIAL)))
  );
}

async function fetchChatbotDirectoryAll(config: Config | null) {
  const [verse, odience] = config
    ? await Promise.all([
        fetchChatbotDirectoryVerse(config),
        fetchChatbotDirectoryOdience(config),
      ])
    : [null, null];
  const bots = [...(verse || []), ...(odience || [])];

  // Remove duplicates and pollbot
  return bots.filter((bot, index, self) => {
    const botId = bot.id || bot.bot_id;

    if (
      !botId ||
      isPollBot(bot) ||
      // remove duplicates
      self.findIndex((b) => (b.id || b.bot_id) === bot.id) !== index
    ) {
      return false;
    }

    return true;
  });
}

async function fetchChatbotDirectoryOdience(config: Config) {
  const bots = await fetchChatbotDirectory(
    // TODO - create ACS config for that
    config["application/messaging/chatbot/chatbotdirectory"].replace(
      "carrier=" + CARRIER_VERSE,
      "carrier=" + CARRIER_ODIENCE
    )
  );
  if (!bots) return bots;

  for (const bot of bots) {
    bot.odience = true;
  }
  return bots;
}

async function fetchChatbotDirectoryVerse(config: Config) {
  const bots = await fetchChatbotDirectory(
    config["application/messaging/chatbot/chatbotdirectory"]
  );
  if (!bots) return bots;

  for (const bot of bots) {
    bot.odience = false;
  }
  return bots;
}

async function fetchChatbotDirectory(url: string) {
  try {
    const res = await fetchWithTimeout(url, {}, 2000);
    if (!res?.ok) {
      console.error("Unable to fetch chatbot directory", res?.status);
      return null;
    }

    return ((await res?.json()) as ChatbotDirectoryRes).bots.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  } catch {
    return null;
  }
}

export async function fetchChatbotDirectoryCategorized(config: Config | null) {
  return categorizeBots(await fetchChatbotDirectoryAll(config));
}

function categorizeBots(bots?: ChatbotInfo[] | null) {
  const featuredBots = [] as (FeaturedBot | null)[];
  const updatedBots = [] as (UpdatedBot | null)[];
  const newBots = [] as (NewBot | null)[];

  if (bots) {
    for (const bot of bots) {
      if (isFeaturedBot(bot)) {
        featuredBots.push(bot);
      }
      if (isUpdatedBot(bot)) {
        updatedBots.push(bot);
      }
      if (isNewBot(bot)) {
        newBots.push(bot);
      }
    }
  }

  return [
    bots,
    {
      Featured: featuredBots,
      Updated: updatedBots,
      New: newBots,
    },
  ] as const;
}

export type FeaturedBot = ChatbotInfo & { isFeatured: true };
export function isFeaturedBot(bot: ChatbotInfo): bot is FeaturedBot {
  return bot.isFeatured;
}

export type UpdatedBot = ChatbotInfo & { isUpdated: true };
export function isUpdatedBot(bot: ChatbotInfo): bot is UpdatedBot {
  return bot.isUpdated;
}

export type NewBot = ChatbotInfo & { isNew: true };
export function isNewBot(bot: ChatbotInfo): bot is NewBot {
  return bot.isNew;
}

type ChatbotBannersRes = {
  id: number;
  url: string; // "https://moderator.rcsplugins.com/assets/bot-banner/72/banner_2084.png";
  name: string; // "2084";
  bot_id: string; // "<sip:2084botid1272@botplatform.mnc000.mcc000.3gppnetwork.org>";
  is_ad: number; // 0
  sort_order: number; // 1
  is_enabled: number; // 1
  created_at: string; // "2019-09-10 15:44:43";
  updated_at: string; // "2019-09-10 15:44:43";
}[];
export async function fetchChatbotBanners(config: Config | null) {
  if (!config) {
    return null;
  }

  try {
    const res = await fetch(
      config["application/messaging/chatbot/bannerboturl"]
    );
    if (!res.ok) {
      console.error("Unable to fetch chatbot banners", res.status);
      return null;
    }
    return res.json() as Promise<ChatbotBannersRes>;
  } catch {
    return null;
  }
}

export async function fetchChatbotInfo(botIdToFetch: string, config: Config) {
  const verse = config["application/messaging/chatbot/botinfofqdnroot"];
  const odience = verse.replace("/" + CARRIER_VERSE, "/" + CARRIER_ODIENCE);
  const params = `bot?id=${encodeURIComponent(
    botIdToFetch
  )}&hl=en&ho=000000&v=2`;

  let res = await fetch(`https://${verse}/${params}`, {
    cache: "no-store",
  }).catch(() => null);
  if (!res?.ok) {
    // Check on odience
    res = await fetch(`https://${odience}/${params}`, {
      cache: "no-store",
    }).catch(() => null);
    if (!res?.ok) {
      console.error("unable to fetch bot info");
      return null;
    }
  }
  const {
    botinfo: botInfo,
    "persistent-menu": pm,
    website,
    TCPage,
  } = (await res.json()) as ModeratorBotInfo;

  return {
    description: botInfo.pcc["org-details"]?.["org-description"],
    addressEntries: botInfo.address?.["address-entry"].filter(
      (addressEntry) => addressEntry["addr-string"]
    ),
    persistentMenu: pm?.menu.entries,
    provider: botInfo.provider,
    color: botInfo.colour ? atob(botInfo.colour) : undefined,
    website:
      website ||
      TCPage ||
      botInfo.pcc["org-details"]?.["web-resources"]?.["web-entry"].find(
        (w) => w.label === "Website"
      )?.url,
    mediaExtraInfo: {
      mediaAlignment: botInfo.summit?.mediaAlignment,
      mediaLayout: botInfo.summit?.mediaLayout,
    },
  };
}
