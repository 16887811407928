import { FastAverageColor, FastAverageColorOptions } from "fast-average-color";
import { queryChatbotDirectory } from "../queries/chatbots";
import { queryClient } from "../queryClient";

export const fac = new FastAverageColor();
export const getAvgColorOptions: FastAverageColorOptions = {
  step: 2,
  ignoredColor: [
    [255, 255, 255, 255, 20],
    [0, 0, 0, 0, 0],
  ],
  algorithm: "dominant",
};

export const defaultChatbotImgUrl = "/assets/Chatbot_Default_Avatar.svg";
export const defaultChatbotImgBackgroundColorPromise = fac
  .getColorAsync(defaultChatbotImgUrl, getAvgColorOptions)
  .then((avgColor) => avgColor.rgb);

export function getLoadedChatbots() {
  return queryClient.getQueryData(queryChatbotDirectory(null).queryKey);
}

export function isChatbot(str?: string) {
  return !!str?.includes("botplatform");
}
