import { queryOptions } from "@tanstack/react-query";
import { getLocalAccessToken } from "../helpers/localstorage";
import { login } from "../helpers/loginAndCaps";
import { handleReloginMechanic } from "../hooks/useWebgwSubscription";

export const loginQuery = queryOptions({
  queryKey: ["login"],
  queryFn: async () => {
    const accessToken = getLocalAccessToken();
    if (!accessToken) {
      // Without an accessToken, /login is impossible.
      await handleReloginMechanic(false);
      return false;
    }

    console.log("loginQuery: calling Login");
    const loginResponse = await login(accessToken);
    console.log(
      "loginQuery: loginResponse:",
      loginResponse?.status,
      loginResponse?.statusText
    );
    if (!loginResponse?.ok) {
      await handleReloginMechanic(false);
      // we have to return false until the retry mechanism ends.
      return false;
    }
    // We return true if the first login function worked.
    return true;
  },
});
