import { queryOptions } from "@tanstack/react-query";
import { sleep } from "..";
import {
  fetchContacts,
  getBlockedContactsList,
} from "../helpers/fetchContacts";
import WebGwContact from "../helpers/WebGwContact";

export const contactsQuery = queryOptions({
  queryKey: ["contacts"],
  queryFn: async () => {
    const maxRetryAttempts = 5;
    const retryDelay = 5000;

    for (
      let retryAttempt = 0;
      retryAttempt < maxRetryAttempts;
      retryAttempt++
    ) {
      try {
        const contacts = await fetchContacts();
        if (contacts !== null) {
          return contacts;
        }
      } catch (error) {
        console.error("Unable to fetch contacts", error);
      }

      if (retryAttempt < maxRetryAttempts - 1) {
        await sleep(retryDelay);
      }
    }

    return null;
  },
});

export const blockedContactsQuery = queryOptions({
  // Right now there is no websocket in place to know when a contact has been blocked by another client using the same number, cache will be valid for only one second
  staleTime: 1000,
  queryKey: ["blockedContacts"],
  queryFn: async () => {
    try {
      return await getBlockedContactsList();
    } catch (error) {
      console.error("Error fetching data: ", error);
    }

    return null;
  },
});

export const contactPhoneNumbersWithTypesAndCapsQuery = (
  contact: WebGwContact | undefined
) =>
  queryOptions({
    queryKey: ["contactPhoneNumbers", contact?.id],
    queryFn: () => contact?.getAllPhoneNumbersWithTypesAndCaps(),
    enabled: !!contact,
  });
