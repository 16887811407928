import React from "react";

const CopyIcon = (props: React.ComponentProps<"svg">) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      transform="translate(-1321.091 -559.091)"
    >
      <rect
        width={12.764}
        height={12.764}
        data-name="Rectangle 57"
        rx={2}
        transform="translate(1327.327 565.327)"
      />
      <path
        d="M1325.036 572.855h-.981a1.963 1.963 0 0 1-1.964-1.964v-8.836a1.963 1.963 0 0 1 1.964-1.964h8.836a1.963 1.963 0 0 1 1.964 1.964v.982"
        data-name="Path 2654"
      />
    </g>
  </svg>
);
export default CopyIcon;
