import { paths } from "@/routerPaths";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { atoms } from "../helpers/atoms";
import {
  GRANTED,
  PROMPT,
  checkMicPermissions,
  checkVideoCallPermissions,
  racePromisesWithTimeout,
  requestAudioCallPermission,
  requestVideoCallPermissions,
} from "../helpers/browserPermissions";
import {
  LocalHardwareStatusValues,
  checkAvailableHardware,
} from "../helpers/checkAvailableDeviceHardware";
import { useToast } from "../helpers/toastManager";
import {
  CapabilityType,
  checkCapabilityOnServer,
} from "../hooks/useCapabilities";

type UseCall = {
  callWithAudio: (number: string, nocheck?: boolean) => void;
  callWithVideo: (number: string, nocheck?: boolean) => void;
  canAcceptOrMakeCall: boolean;
};

export function useCall(): UseCall {
  const { showToast } = useToast();
  const [outgoingCallInfos, setOutgoingCallInfos] = useAtom(
    atoms.calling.outgoingCallInfos
  );
  const incomingCallInfos = useAtomValue(atoms.calling.incomingCallInfos);
  const callActive = useAtomValue(atoms.calling.callActive);
  const [waitingForPermissions, setWaitingForPermissions] = useAtom(
    atoms.calling.waitingForPermissions
  );
  const setShowHardwareModal = useSetAtom(atoms.calling.checkAvailableHardware);

  const callWithVideo = async (numberToCall, nocheck) => {
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable !== LocalHardwareStatusValues.AVAILABLE) {
      setShowHardwareModal(true);
    }
    call(
      numberToCall,
      true,
      checkVideoCallPermissions,
      requestVideoCallPermissions,
      nocheck
    );
  };

  const callWithAudio = async (numberToCall, nocheck) => {
    const hardwareAvailable = await checkAvailableHardware();
    if (hardwareAvailable === LocalHardwareStatusValues.NO_MIC) {
      setShowHardwareModal(true);
    }
    call(
      numberToCall,
      false,
      checkMicPermissions,
      requestAudioCallPermission,
      nocheck
    );
  };

  const canAcceptOrMakeCall = () => {
    return (
      !outgoingCallInfos &&
      !incomingCallInfos &&
      !callActive &&
      !waitingForPermissions
    );
  };

  const call = async (
    numberToCall,
    isVideo,
    checkPermission: (
      checkPromptState?: boolean
    ) => Promise<string | undefined>,
    requestPermission: () => Promise<void>,
    nocheck?: boolean
  ) => {
    if (!nocheck && !canAcceptOrMakeCall()) {
      return;
    }

    setWaitingForPermissions(true);

    const permission = await callPhoneNumberWithPermission(
      numberToCall,
      isVideo,
      checkPermission,
      requestPermission
    );

    //@ts-expect-error
    if (permission && (permission !== GRANTED || permission !== PROMPT)) {
      showToast(permission);
    }
    setWaitingForPermissions(false);
  };

  async function callPhoneNumberWithPermission(
    phoneNumber: string,
    isVideo: boolean,
    checkPermission: (
      checkPromptState?: boolean
    ) => Promise<string | undefined>,
    requestPermission: () => Promise<void>
  ) {
    const permission = await checkPermission();
    if (permission === GRANTED || !navigator.permissions) {
      callPhoneNumber(phoneNumber, isVideo);
    } else if (permission === PROMPT) {
      await requestPermission();
      try {
        const [updatedPermission] = await racePromisesWithTimeout(
          [checkPermission(false)],
          10000
        );

        if (updatedPermission === GRANTED) {
          callPhoneNumber(phoneNumber, isVideo);
        } else {
          return updatedPermission;
        }
      } catch (error) {
        console.error("Error checking permission:", error);
      }
    } else {
      return permission;
    }
  }

  function callPhoneNumber(phoneNumber: string, isVideo: boolean) {
    console.log(
      `callPhoneNumber: phoneNumber ${phoneNumber}, isVideo=${isVideo}`
    );

    // A call could be initialized with unknown numbers. Check caps here for the feedback on end call and also for next calling with same number (cache will be available before making the call).
    checkCapabilityOnServer(
      phoneNumber,
      isVideo ? CapabilityType.VIDEO : CapabilityType.VOICE
    );

    // Fullscreen everywhere except on Odience stream page
    const startFullScreen = !location.href.includes(
      paths.stream.substring(0, paths.stream.indexOf(":"))
    );

    setOutgoingCallInfos({
      number: phoneNumber,
      isVideo,
      startFullScreen,
    });
  }

  return {
    callWithAudio,
    callWithVideo,
    canAcceptOrMakeCall: canAcceptOrMakeCall(),
  };
}

export const captureFrameEverySecond = (
  outgoingVideoRef: React.RefObject<HTMLVideoElement | null> | undefined,
  callback: (base64Image: string) => void
): (() => void) | undefined => {
  if (!outgoingVideoRef?.current) {
    return; // outgoingVideoRef is undefined or the current element is not available
  }

  const videoElement = outgoingVideoRef.current;
  const canvas = document.createElement("canvas");

  const captureFrame = () => {
    if (videoElement && videoElement.readyState === 4) {
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;

      canvas.width = videoWidth;
      canvas.height = videoHeight;

      const context = canvas.getContext("2d");
      if (context) {
        // Draw the current frame from the video onto the canvas
        context.drawImage(videoElement, 0, 0, videoWidth, videoHeight);

        // Convert the canvas content to a base64-encoded PNG image
        const base64Image = canvas.toDataURL("image/png");

        // Extract only the base64-encoded part (remove "data:image/png;base64,")
        const base64Content = base64Image.split(",")[1];

        // Call the callback with the base64 content
        callback(base64Content);
      }
    }
  };

  // Set up an interval to capture a frame every second
  const intervalId = setInterval(captureFrame, 1000);

  return () => clearInterval(intervalId);
};
