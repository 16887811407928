import { colors } from "@/styles/global.styles";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { useToast } from "@/utils/helpers/toastManager";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import DialpadIcon from "@mui/icons-material/Dialpad";
import GestureIcon from "@mui/icons-material/Gesture";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MicNoneIcon from "@mui/icons-material/MicNone";
import MicOffIcon from "@mui/icons-material/MicOff";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useAtomValue } from "jotai";
import FloatingTooltip from "../shared/FloatingTooltip";
import {
  ControlBar,
  ControlButtonLarge,
  ControlButtonSmall,
} from "./Overlay.style";

export interface CallButtonsProps {
  callActive: boolean;
  callMuted: boolean;
  muteCall: () => void;
  endCall: () => void;
  showNotImplemented: () => void;
  sendVideoOffer: () => void;
  videoCallOpen?: boolean;
  isFullScreen: boolean;
  isOdienceCall?: boolean;
  disableAudioCallingSupport?: boolean;
  videoOfferInProgress?: boolean;
}

export type CallOverlayProps = CallButtonsProps & {
  open: boolean;
  isFullScreen: boolean;
  contact: WebGwContact | undefined;
  time: Date;
  toggleFullScreen: () => void;
  isPaused: boolean;
  isVideoWallCall?: boolean;
  isFrontRowCall?: boolean;
  isFeaturedCaller?: boolean;
};
export function ButtonBar({
  callActive,
  callMuted,
  muteCall,
  endCall,
  sendVideoOffer,
  showNotImplemented,
  videoCallOpen,
  isFullScreen,
  isOdienceCall,
  disableAudioCallingSupport,
  videoOfferInProgress,
}: CallButtonsProps) {
  const { showToast } = useToast();
  const mutedByModerator = useAtomValue(atoms.odience.mutedByModerator);
  const notImplemented = false;

  const ControlButton = isFullScreen ? ControlButtonLarge : ControlButtonSmall;

  return (
    <div
      css={{
        display: "flex",
        gap: "2em",
        justifyContent: "center",
        filter: `drop-shadow(0 0 4px rgb(0,0,0,0.25))`,
      }}
    >
      <ControlBar css={!isFullScreen && { borderRadius: "6px" }}>
        <FloatingTooltip tooltipContent={"End Call"}>
          <button
            css={[
              ControlButton,
              {
                backgroundColor: colors.primaryAccentColor,
                ":hover": { backgroundColor: colors.primaryAccentColor },
              },
            ]}
            onClick={endCall}
          >
            <CallEndOutlinedIcon />
          </button>
        </FloatingTooltip>
        <FloatingTooltip
          tooltipContent={callMuted || mutedByModerator ? "Unmute" : "Mute"}
        >
          <button
            css={ControlButton}
            onClick={
              isOdienceCall && mutedByModerator
                ? () => showToast("Audio is controlled by the moderator")
                : muteCall
            }
          >
            {callMuted || mutedByModerator ? <MicOffIcon /> : <MicNoneIcon />}
          </button>
        </FloatingTooltip>
        {notImplemented && (
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <PauseOutlinedIcon />
          </button>
        )}
        {/* Disable downgrade if audio calling not supported */}
        {!isOdienceCall &&
          (!videoCallOpen ||
            (videoCallOpen && !disableAudioCallingSupport)) && (
            <FloatingTooltip
              tooltipContent={videoCallOpen ? "Downgrade" : "Upgrade"}
            >
              <button
                css={ControlButton}
                disabled={!callActive || videoOfferInProgress}
                onClick={sendVideoOffer}
              >
                <VideocamOutlinedIcon
                  css={{
                    color: videoCallOpen
                      ? colors.primaryAccentColor
                      : colors.secondaryAccentColor,
                  }}
                />
              </button>
            </FloatingTooltip>
          )}
      </ControlBar>
      {notImplemented && (
        <ControlBar>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <AddIcCallOutlinedIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <DialpadIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <MessageOutlinedIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <GestureIcon />
          </button>
          <button
            css={ControlButton}
            disabled={!callActive}
            onClick={showNotImplemented}
          >
            <MapOutlinedIcon />
          </button>
        </ControlBar>
      )}
    </div>
  );
}
