import { barStyles } from "./ThreeLines.style";

export function ThreeLinesLoader({
  relativeHeight = false,
}: {
  relativeHeight?: boolean;
}) {
  return (
    <>
      <span css={barStyles(relativeHeight)}></span>
      <span css={barStyles(relativeHeight)}></span>
      <span css={barStyles(relativeHeight)}></span>
    </>
  );
}

export function ThreeLinesLoaderBox({
  absolute,
  relativeHeight,
  ...props
}: React.ComponentProps<"div"> & {
  relativeHeight?: boolean;
  absolute?: boolean;
}) {
  return (
    <div
      {...props}
      css={[
        {
          height: "100%",
          width: "100%",
          opacity: "0.6",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        absolute && {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      ]}
    >
      <ThreeLinesLoader relativeHeight={relativeHeight} />
    </div>
  );
}

export function ThreeLinesLoaderButton({
  absolute,
  ...props
}: React.ComponentProps<"div"> & { absolute?: boolean }) {
  return (
    <ThreeLinesLoaderBox absolute={absolute} relativeHeight={true} {...props} />
  );
}
