import { createHashRouter, Navigate, redirect } from "react-router-dom";
import RouterErrorHandler from "./RouterErrorHandler";

export const paths = {
  any: "*",
  root: "/",
  odience: "/odience",
  details: "/event/stream/:eventId/",
  stream: "/event/details/:groupId/:eventId/",
} as const;

const defaultPage = paths.odience;

/**
 * preloads pages (browser caches these imports)
 */
async function preloadPages() {
  await Promise.allSettled([
    import("./components/odience/pages/eventList/EventListPage"),
    import("./components/odience/pages/eventDetails/EventDetailsPage"),
    import("./components/odience/pages/eventStream/EventStreamPage"),
  ]);
}

export const routerOdience = createHashRouter([
  {
    path: paths.any,
    element: <Navigate to={defaultPage} />,
  },
  {
    path: paths.root,
    errorElement: <RouterErrorHandler />,
    loader: ({ request }) => {
      setTimeout(preloadPages, 1000);

      const url = new URL(request.url);
      if (url.pathname === paths.root) {
        return redirect(defaultPage);
      }

      return null;
    },
    children: [
      {
        path: paths.odience,
        lazy: async () => {
          const { default: EventList } = await import(
            "./components/odience/pages/eventList/EventListPage"
          );
          return {
            element: <EventList />,
          };
        },
      },
      {
        path: paths.stream,
        lazy: async () => {
          const { default: EventDetails } = await import(
            "./components/odience/pages/eventDetails/EventDetailsPage"
          );
          return {
            element: <EventDetails odienceOnly={true} />,
          };
        },
      },
      {
        path: paths.details,
        lazy: async () => {
          const { default: EventStream } = await import(
            "./components/odience/pages/eventStream/EventStreamPage"
          );
          return {
            element: <EventStream odienceOnly={true} />,
          };
        },
      },
    ],
  },
]);
