import { DeviceIdentityData } from "@/types/common";
import { baseWebGwUrl } from "../..";
import {
  getBrowserName,
  getBrowserVendor,
  getBrowserVersion,
} from "../../../utils/helpers/getBrowser";
import { fetchWithTimeout } from "../Utils";
import {
  getImpi,
  getLocalDirectorToken,
  getPassword,
  getSipUser,
  getUsername,
} from "../localstorage";
import {
  LOGIN_RESULT_NOTIFICATION_STATUS_CODE,
  LoginResultNotification,
  WebGwResponse,
} from "../provisionRequest";
import { generateDeviceId } from "./publishCaps";
export const domain = window?._env_?.DOMAIN;
export const PROV_WAIT_TIMEOUT_MS = 30000;

export async function register(
  user: string,
  deviceName: string,
  force: boolean = false
) {
  const browserName = getBrowserName(navigator.userAgent);
  const browserVendor = getBrowserVendor(navigator.userAgent);
  const browserVersion = getBrowserVersion(navigator.userAgent);
  const impi = getImpi();
  const deviceId = generateDeviceId(user, deviceName);
  console.log("DeviceId:", deviceId);
  if (!user) {
    const provPath: string = `/client/v1/odience?device_id=${deviceId}`;
    const headers = {
      Authorization: `Bearer ${getLocalDirectorToken()}`,
      Accept: "application/json",
    };
    const registerRes = await fetchWithTimeout(
      new URL(provPath, baseWebGwUrl),
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ force: true }),
      }
    );
    return registerRes;
  }
  // const provUrl: string = "/client/v1/register";
  const provPath: string = `/client/v1/odience?msisdn=${encodeURIComponent(
    user
  )}`;
  const registerRes = await fetchWithTimeout(new URL(provPath, baseWebGwUrl), {
    method: "POST",
    body: JSON.stringify({
      uri: `sip:${user}@${domain}`,
      authuser: `${user}@${domain}`,
      terminal_model: browserName,
      client_vendor: "STVE",
      client_version: "web-1.0",
      terminal_sw_version: browserVersion,
      terminal_vendor: browserVendor,
      friendly_device_name: deviceName,
      device_id: deviceId,
      impi: impi,
      force: force,
    } satisfies DeviceIdentityData),
  });
  return registerRes;
}

export async function provWait(accessToken: string) {
  console.info("Sending /provwait request while waiting for OTP");
  const provWaitRes = await fetchWithTimeout(
    new URL(
      `/client/v1/provwait?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    },

    PROV_WAIT_TIMEOUT_MS
  ).catch((e) => {
    // Timeout, create a fake otp expired response

    if (e.name === "AbortError") {
      const loginResultNotification: LoginResultNotification = {
        reason: "OTP not provided in time",
        status_code: LOGIN_RESULT_NOTIFICATION_STATUS_CODE.OtpNotProvided,
        access_token: "",
        state: "",
        result: "",
      };

      const webGwResponse: WebGwResponse = {
        notificationList: {
          loginResultNotification: loginResultNotification,
        },
      };

      const response = new Response(JSON.stringify(webGwResponse), {
        status: 408,
        headers: { "Content-Type": "application/json" },
      });

      return response;
    } else {
      return e;
    }
  });
  return provWaitRes;
}
//Used after Otp so that there is no timeout on the Device Management provisioning step
export async function provWaitAfterOtp(accessToken: string, signal) {
  console.info("Sending /provwait request after correct OTP");
  const provWaitRes = fetch(
    new URL(
      `/client/v1/provwait?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
      signal: signal,
    }
  ).catch((e) => {
    return e;
  });
  return provWaitRes;
}

export async function abortProv(accessToken: string) {
  console.info("Sending /abortprov request");
  const provAbortRes = fetch(
    new URL(`/client/v1/abortprov?access_token=${accessToken}`, baseWebGwUrl),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    }
  ).catch((e) => {
    return e;
  });
  return provAbortRes;
}

export async function otpRequest(otp: string, accessToken: string) {
  console.debug(`[${accessToken}]: Fetched OTP, sending OTP '${otp}'`);
  const res = await fetchWithTimeout(
    new URL(
      `/anonymous/otp?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        OTP: otp,
      }),
      cache: "no-store",
    }
  );
  if (res?.ok) {
    console.debug("Correctly Sent OTP", res);
    localStorage.setItem("OTP", accessToken); // TODO OTP shouldn't need to be set in local storage
  } else {
    console.warn("Failed to send OTP, res:", res);
  }
}

export async function login(accessToken: string) {
  const loginRes = await fetchWithTimeout(
    new URL(
      `/client/v1/login?access_token=${accessToken}&_t=${Date.now()}&resFormat=json`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
    }
  );
  return loginRes;
}

export async function relogin() {
  const sipUser = getSipUser();
  const username = getUsername();
  const password = getPassword();
  if (
    sipUser &&
    username &&
    password &&
    sipUser.length > 0 &&
    username.length > 0 &&
    password.length > 0
  ) {
    const loginRes = await fetchWithTimeout(
      new URL(
        `/client/v1/relogin?_t=${Date.now()}&resFormat=json`,
        baseWebGwUrl
      ),
      {
        method: "POST",
        referrerPolicy: "no-referrer-when-downgrade",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        credentials: "include",
        cache: "no-store",
        body: JSON.stringify({
          uri: sipUser,
          username: username,
          password: password,
        }),
      }
    );
    return loginRes;
  }
}
