import { queryOptions } from "@tanstack/react-query";
import { fetchChatbotDirectoryCategorized } from "../chatbots";
import { getConfig } from "../helpers/config";
import { queryClient } from "../queryClient";

export const queryChatbotDirectory = (
  config: Awaited<ReturnType<typeof getConfig>>
) =>
  queryOptions({
    queryKey: ["chatbotDirectory"],
    queryFn: () => fetchChatbotDirectoryCategorized(config),
  });

export async function getChatbotDirectory() {
  const config = await getConfig();
  return queryClient.fetchQuery(queryChatbotDirectory(config));
}
