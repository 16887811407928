import { css } from "@emotion/react";

export const barStyles = (relativeHeight?: boolean) =>
  css({
    display: "inline-block",
    width: "3px",
    height: relativeHeight ? "60%" : "20px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: "10px",
    animation: "scale-up4 1.4s ease-in-out infinite",

    "&:nth-of-type(2)": {
      height: relativeHeight ? "100%" : "35px",
      margin: "0 5px",
      animationDelay: "0.25s",
    },

    "&:nth-of-type(3)": {
      animationDelay: "0.5s",
    },

    "@keyframes scale-up4": {
      "20%": {
        backgroundColor: "#ffff",
        transform: "scaleY(1.5)",
      },
      "40%": {
        transform: "scaleY(1)",
      },
    },
  });
